import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';

import {forwardRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const qql_get_offers_for_list_component = gql`
  query GetOffersForOfferListComponent {
    getOffers {
      offers {
        id
        header
        modified
        created
        status
      }
    }
  }
`;

export const qql_get_offers_by_partner = gql`
  query GetOffersByPartner($partner: String) {
    getOffers(partner: $partner) {
      offers {
        id
        body
        brand
        created
        cta
        category
        disclaimer
        discountCode
        header
        imageUrl
        keywords
        modified
        owner
        referralUrl
        status
        address
        city
        postalCode
        dueDate
        adultsOnly
        futurePublicationDate
        offerDuration
      }
    }
  }
`;

export const gql_update_offer_status = gql`
  mutation UpdateOfferStatus($id: ID!, $status: OfferStatus!) {
    updateOfferStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const gql_update_offer_status_and_dueDate = gql`
  mutation UpdateOfferStatusAndDueDate($id: ID!, $status: OfferStatus!, $dueDate: String!, $futurePublicationDate: String) {
    updateOfferStatusAndDueDate(id: $id, status: $status, dueDate: $dueDate, futurePublicationDate: $futurePublicationDate) {
      id
      status
      dueDate
      futurePublicationDate
    }
  }
`;

export const gql_update_offer_adults_only = gql`
  mutation UpdateOfferAdultsOnly($id: ID!, $adultsOnly: Boolean!) {
    updateOfferAdultsOnly(id: $id, adultsOnly: $adultsOnly) {
      id
      adultsOnly
    }
  }
`;

export const formatDates = (offer) => {
  const dateFormat = 'D.M.YYYY HH:mm';
  return {
    ...offer,
    // generateNewDueDate needs the created to stay in ISO string format
    // therefore we are creatin new fields for the formatted dates
    modified_formatted: moment(offer.modified).format(dateFormat),
    created_formatted: moment(offer.created).format(dateFormat),
  };
};

export const offerCategoryTranslator = (number) => {
  switch (number) {
    case 0:
      return 'All';
    case 2:
      return 'Electronics';
    case 3:
      return 'Home & Family';
    case 4:
      return 'Transportation';
    case 5:
      return 'Food & Beverages';
    case 6:
      return 'Sports';
    case 7:
      return 'Clothes & Accessories';
    case 8:
      return 'Going Out';
    case 9:
      return 'Health & Wellbeing';
    case 18:
      return 'Grocery Store';
    case 19:
      return 'Jobs';
    case 20:
      return 'Quicces';
    default:
      return 'no category selected';
  }
};

export const formatStatus = (offer) => {
  const statusMapper = (status) => {
    switch (status) {
      case 'PAID':
        return 'Paid, pending approval';
      case 'PAYMENTFAILED':
        return 'Payment failed';
      case 'PENDING':
        return 'Pending, not paid';
      case 'APPROVED':
        return 'Paid, approved';
      case 'REJECTED':
        return 'Paid, rejected';
      case 'PUBLISHED':
        return 'Paid, published';
      case 'PUBLISHEDLATER':
        return 'Paid, Approved, waiting to be published';
      case 'UNPUBLISHED':
        return 'Paid, unpublished';
      case 'EXPIRED':
        return 'Paid, expired';
      // no default
    }
  };
  return {
    ...offer,
    status_label: statusMapper(offer.status),
  };
};

export const capitalizeHeader = (offer) => {
  return {
    ...offer,
    header: offer.header.toUpperCase(),
  };
};

export const OfferStatus = {
  PAYMENTFAILED: 'PAYMENTFAILED',
  PAID: 'PAID',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PUBLISHED: 'PUBLISHED',
  PUBLISHEDLATER: 'PUBLISHEDLATER',
  UNPUBLISHED: 'UNPUBLISHED',
  EXPIRED: 'EXPIRED',
};

function addMonthsToDate(date, monthsToAdd) {
  const newDate = new Date(date);
  const totalMonths = newDate.getMonth() + monthsToAdd;
  newDate.setFullYear(newDate.getFullYear() + Math.floor(totalMonths / 12));
  newDate.setMonth(totalMonths % 12);
  return newDate;
}

export const getNewDueDate = (offerDuration, publicationDate = null) => {
  let newDueDate = new Date();
  // Below we neutralize the effects of someone moderating the offer with a
  // delay of more than a day. So the partners always get their moneys worth.
  if (publicationDate) {
    // if we have a publication date, then the offer is published later
    // and the duedate is calculated from the publication date
    newDueDate = addMonthsToDate(publicationDate, offerDuration);
  } else {
    // if we don't have a publication date, then the offer is published
    // immediately and the duedate is calculated from the current date.
    newDueDate = addMonthsToDate(newDueDate, offerDuration);
  }
  return newDueDate.toISOString();
};